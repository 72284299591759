import React, { useState, useEffect, useRef } from "react";
import SelectionGrid from "./SelectionGrid";
//searchActors from api
import { searchActors, targetActorCheck}   from "../api";
//fetch actors from the backend

const ActorSelectionModal = ({ onClose, onActorSelect, actorType, startingActorId }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState("");
    const searchInputRef = useRef(null); // Create a ref for the input
    useEffect(() => {
        // Focus the input when the modal opens
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []); // Run once when the component mounts
    const handleSearch = async () => {
        if (!searchTerm) {
            setError("Please enter a search term.");
            return;
        }

        try {
            const response = await searchActors(searchTerm);
            setSearchResults(response.actors);
            setError("");
        } catch (error) {
            console.error("Failed to search for actors:", error);
            setError("Failed to search for actors. Please try again later.");
        }

    };
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent form submission
        handleSearch();
    };
    const handlePreview = (actor) => {
        alert(`WIP Previewing movies for ${actor.name}`); //ToDO: Implement previewing movies for the actor
    };

    const handleSelect = async (actor) => {
        if (actorType === "target") {
            // use targetActorCheck and the ides of the starting actor and the target actor to check if the target actor is reachable
            const targetActorId = actor.id;
            if(startingActorId === 0 || startingActorId === null || startingActorId === undefined){
                onActorSelect(actor);
                return;
            }
            const response = await targetActorCheck(startingActorId, targetActorId);
            if (!response.check) {
                setError("Target actor and starting actor have worked together.");
                return;
            }
        }
        onActorSelect(actor);
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{actorType === "starting" ? "Select Starting Actor" : "Select Target Actor"}</h2>
                <form className="search-bar" onSubmit={handleSubmit}>
                    <input
                        ref={searchInputRef} // Attach the ref to the input
                        type="text"
                        placeholder="Search for an actor..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button type="submit">Search</button>
                </form>
                {error && <p className="error-message">{error}</p>}
                <SelectionGrid
                    items={searchResults}
                    onItemClick={handleSelect}
                    onPreviewClick={handlePreview}
                />
                <div className="modal-actions">
                    <button onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ActorSelectionModal;
