import React, { useState } from "react";
import { clearCookies,logoutUser } from "../api";

function ProfileDropdown({ onPreferencesUpdate }) { // Destructure props correctly
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        logoutUser().then(() => {
            clearCookies();
            window.location.href = "/";

        });
    };

    return (
        <div className={`profile-container ${isOpen ? "active" : ""}`}>
            <button className="profile-button" onClick={toggleDropdown}>
                Profile ▼
            </button>
            {isOpen && (
                <div className="profile-menu">
                    <button onClick={onPreferencesUpdate}>Preferences</button>
                    <button onClick={handleLogout}>Log Out</button>
                </div>
            )}
        </div>
    );
}

export default ProfileDropdown;
