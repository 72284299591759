const BASE_URL = "https://moviebacon.com/backend"; // This should match Nginx setup
// const BASE_URL = "http://localhost:8080/backend"; // local testing

export const fetchWithAuth = async (url, options = {}) => {
    const response = await fetch(url, {
        ...options,
        credentials: "include", // Ensure cookies are sent with every request
    });

    if (response.status === 401) { // Handle SC_UNAUTHORIZED
        clearCookies();
        window.location.href = "/"; // Redirect to login page
        throw new Error("Unauthorized: Redirecting to login.");
    }

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "An error occurred.");
    }

    return response.json(); // Return parsed JSON response
};

export const clearCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
        document.cookie = cookie.replace(
            /=.*/,
            `=;expires=${new Date().toUTCString()};path=/`
        );
    });
};

export const registerUser = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/register`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            const result = await response.json();
            console.log(result.message);
            return true; // Success
        } else {
            const error = await response.json();
            console.error("Failed to register:", error.error);
            return false;
        }
    } catch (error) {
        console.error("Error during registration:", error);
        return false;
    }
};

export const loginUser = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/login`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            const result = await response.json();
            console.log("Login successful:", result);
            return result; // Return session and user info
        } else {
            const error = await response.json();
            console.error("Failed to login:", error.error);
            return null;
        }
    } catch (error) {
        console.error("Error during login:", error);
        return null;
    }
};

//logout
export const logoutUser = async () => {
    try {
        const response = await fetchWithAuth(`${BASE_URL}/logout`, {
            method: "POST",
        });

        if (response.ok) {
            console.log("Logged out successfully.");
            return true;
        } else {
            const error = await response.json();
            console.error("Failed to logout:", error.error);
            return false;
        }
    } catch (error) {
        console.error("Error during logout:", error);
        return false;
    }
};



// /quickstart
export const getQuickstart = async (options = {}) => {
    // rename gameTimeLimit to timeLimit if present
    if (options.gameTimeLimit) {
        options.time_limit = options.gameTimeLimit;
        delete options.gameTimeLimit;
    }

    const response = await fetch(`${BASE_URL}/quickstart`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(options),
        credentials: "include",
    });
    if (response.ok) {
        return response.json();
    }
    throw new Error("Failed to start quick play");
};

export const createCustomGame = async (options = {}) => {
    // rename gameTimeLimit to timeLimit if present
    if (options.gameTimeLimit) {
        options.time_limit = options.gameTimeLimit;
        delete options.gameTimeLimit;
    }

    const response = await fetch(`${BASE_URL}/createcustom`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(options),
        credentials: "include",
    });
    if (response.ok) {
        return response.json();
    }
    throw new Error("Failed to start quick play");
};



export const pickOption = async (gameId, selectionType, selectionId, selectionSubType = "null") => {
    return fetchWithAuth(`${BASE_URL}/game/pick`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ gameId, selectionType, selectionId, selectionSubType }),
    });
}

// search actors
export const searchActors = async (query) => {
    //${BASE_URL}/actors/search?query=${query}
   return fetchWithAuth(`${BASE_URL}/search/actors?query=${query}`,{
         method: "GET",

   });

};

export const moveBack = async (gameId) => {
    return fetchWithAuth(`${BASE_URL}/game/back`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ gameId }),
    });
};
//gameStart
export const gameStart = async (gameId) => {
    return fetchWithAuth(`${BASE_URL}/game/start`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ gameId }),
    });
};

export const copyGame = async (gameId) => {
    return fetchWithAuth(`${BASE_URL}/game/copy`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ gameId }),
    });
}

export const askHint = async (gameId) => {
    return fetchWithAuth(`${BASE_URL}/game/hint`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ gameId }),
    });
}

//askHintActor
export const askHintActor = async (gameId, actorId) => {
    return fetchWithAuth(`${BASE_URL}/game/hint/actor`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ gameId, actorId }),
    });
}
export const askHintMovie = async (gameId, movieId) => {
    return fetchWithAuth(`${BASE_URL}/game/hint/movie`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ gameId, movieId }),
    });
}


export const fetchProfile = async () => {
    try {
        return await fetchWithAuth(`${BASE_URL}/profile`, {
            method: "GET",
        });
    } catch (error) {
        console.error("Error fetching profile:", error.message);
        return null;
    }
};

export const updateProfile = async (data) => {
    try {
        await fetchWithAuth(`${BASE_URL}/profile`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
        return true;
    } catch (error) {
        console.error("Error updating profile:", error.message);
        return false;
    }
};

export const searchUsers = async (query) => {
    try {
        const response = await fetchWithAuth(`${BASE_URL}/social/user/search?query=${encodeURIComponent(query)}`, {
            method: "GET",
        });
        return await response;
    } catch (error) {
        console.error("Error searching users:", error.message);
        throw error;
    }
};

export const sendFriendRequest = async (userId) => {
    try {
        await fetchWithAuth(`${BASE_URL}/social/friend/request`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ receiverId: userId }),
        });
    } catch (error) {
        console.error("Error sending friend request:", error.message);
        throw error;
    }
};

export const respondToFriendRequest = async (userId, status) => {
    try {
        await fetchWithAuth(`${BASE_URL}/social/friend/request/respond`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ requestId: userId, status }),
        });
    } catch (error) {
        console.error(`Error responding to friend request (${status}):`, error.message);
        throw error;
    }
};

export const fetchFriends = async () => {
    const response = await fetchWithAuth(`${BASE_URL}/social/friends`);
    return await response;
};

export const fetchRequests = async () => {
    const response = await fetchWithAuth(`${BASE_URL}/social/friend/requests`);
    return await response;
};

export const handleFriendRemove = async (friendship_id) => {
    await fetchWithAuth(`${BASE_URL}/social/friend/remove`, {
        method: "POST",
        body: JSON.stringify({ friendship_id }),
    });
};

export const handleRequestAction = async (requestId, action) => {
    // Validate action
    const actionMapping = {
        accept: "accepted",
        reject: "rejected",
        block: "blocked",
        revoke: "canceled",
        send: null, // `send` doesn't require a mapped status
    };

    if (!Object.keys(actionMapping).includes(action)) {
        throw new Error(`Invalid action: ${action}`);
    }
    action = actionMapping[action];
    // Determine the API endpoint and payload
    const url = action === "send"
        ? `${BASE_URL}/social/friend/request`
        : `${BASE_URL}/social/friend/request/respond`;

    const body = action === "send"
        ? { receiverId: requestId } // Sending a new request
        : { requestId, status: action === "revoke" ? "canceled" : action }; // Responding to a request

    // Perform the API call
    const response = await fetchWithAuth(url, {
        method: "POST",
        body: JSON.stringify(body),
    });

    return response;
};

//targetactorcheck
export const targetActorCheck = async (startingActorId, targetActorId) => {
    return fetchWithAuth(`${BASE_URL}/targetactorcheck`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ startingActorId, targetActorId }),
    });
};

// Fetch play history
export const fetchHistory = async (type) => {

    try {
        return await fetchWithAuth(`${BASE_URL}/history?type=${type}`, {
            method: "GET",
        });
    } catch (error) {
        console.error("Error fetching history:", error.message);
        return null;
    }

};
///social/notifications for getting lsit of notifications from server for player like friend request, game request etc
export const fetchNotifications = async () => {
    try {
        return await fetchWithAuth(`${BASE_URL}/social/notifications`, {
            method: "GET",
        });
    } catch (error) {
        console.error("Error fetching notifications:", error.message);
        return null;
    }
};

///challenges/requests
export const fetchChallengeRequests = async () => {
    try {
        return await fetchWithAuth(`${BASE_URL}/challenges/requests`, {
            method: "GET",
        });
    } catch (error) {
        console.error("Error fetching challenge requests:", error.message);
        return null;
    }
};

//createSendChallengeRequest
export const createSendChallengeRequest = async (payload) => {
    try {
        return await fetchWithAuth(`${BASE_URL}/challenges/requests/createSend`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
        });
    } catch (error) {
        console.error("Error creating challenge request:", error.message);
        return null;
    }
};
///challenges/play
export const fetchChallengePlay = async (challenge_id) => {
    return fetchWithAuth(`${BASE_URL}/challenges/play`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ challenge_id }),
    });
};

//fetchLeaderBoard
export const fetchLeaderBoard = async (challengeId, limit = 5) => {
    return fetchWithAuth(`${BASE_URL}/challenges/fetchLeaderBoard?challenge_id=${challengeId}&count=${limit}`);
};
