import React, { useState, useEffect } from "react";
import { fetchFriends, fetchRequests, handleFriendRemove, handleRequestAction } from "../../api";
import UserSearch from "./UserSearch";

function SocialModal({ onClose }) {
    const [activeTab, setActiveTab] = useState("friends"); // Default tab
    const [friends, setFriends] = useState([]);
    const [outgoingRequests, setOutgoingRequests] = useState([]);
    const [incomingRequests, setIncomingRequests] = useState([]);
    const [error, setError] = useState("");
    const [showSearch, setShowSearch] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const friendsData = await fetchFriends();
            const requestsData = await fetchRequests();

            setFriends(friendsData || []);

            // Separate incoming and outgoing requests based on the new `direction` field
            const outgoing = requestsData.filter((request) => request.direction === "outgoing");
            const incoming = requestsData.filter((request) => request.direction === "incoming");

            setOutgoingRequests(outgoing);
            setIncomingRequests(incoming);
        } catch (err) {
            setError("Failed to load data.");
            console.error(err);
        }
    }

    const handleRemoveFriend = async (friendship_id) => {
        try {
            await handleFriendRemove(friendship_id);
            setFriends(friends.filter((friend) => friend.friendship_id !== friendship_id));
        } catch (err) {
            console.error("Failed to remove friend:", err);
        }
    };

    const handleRequestActionClick = async (requestId, action) => {
        try {
            await handleRequestAction(requestId, action);
            loadData();

        } catch (err) {
            console.error(`Failed to ${action} request:`, err);
            setError(`Failed to ${action} the request. Please try again.`);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Social</h2>
                {error && <p className="error-message">{error}</p>}
                <div className="tabs">
                    <button
                        onClick={() => setActiveTab("friends")}
                        className={activeTab === "friends" ? "active" : ""}
                    >
                        Friends
                    </button>
                    <button
                        onClick={() => setActiveTab("requests")}
                        className={activeTab === "requests" ? "active notifications-button" : "notifications-button"}
                    >
                        {incomingRequests.length > 0 && <span className="notification-badge">{incomingRequests.length}</span>}
                        Requests
                    </button>
                </div>
                <div className="modal-body">
                    {activeTab === "friends" && (
                        <div className="friends-list">
                            {friends.map((friend) => (
                                <div key={friend.friendship_id} className="grid-row">
                                    <span>{friend.display_name}</span>
                                    <span>{friend.username}</span>
                                    <button onClick={() => handleRemoveFriend(friend.friendship_id)}>Remove</button>
                                </div>
                            ))}
                            {friends.length === 0 && <p>No friends found.</p>}
                        </div>
                    )}
                    {activeTab === "requests" && (
                        <div className="requests-container">
                            {/* Fixed header for requests tab */}
                            <div className="requests-header">
                                <button onClick={() => setShowSearch(true)}>Add</button>
                            </div>
                            <h3>Incoming Requests</h3>
                            {/* Scrollable container for incoming requests */}
                            <div className="requests-scroll-container">
                                <div className="requests-section">
                                    {incomingRequests.map((request) => (
                                        <div key={request.request_id} className="request-item">
                                            <div className="request-details">
                                                <span>{request.name}</span>
                                            </div>
                                            <div className="actions">
                                                <button onClick={() => handleRequestActionClick(request.request_id, "accept")}>Accept</button>
                                                <button onClick={() => handleRequestActionClick(request.request_id, "reject")}>Reject</button>
                                                <button onClick={() => handleRequestActionClick(request.request_id, "block")}>Block</button>
                                            </div>
                                        </div>
                                    ))}
                                    {incomingRequests.length === 0 && <p>No incoming requests.</p>}
                                </div>
                            </div>
                            {showSearch && <UserSearch onClose={() => setShowSearch(false)} />}
                        </div>
                    )}
                </div>
                <div className="modal-actions">
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default SocialModal;
