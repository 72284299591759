import React, { useState } from "react";
import { searchUsers, sendFriendRequest, respondToFriendRequest } from "../../api";

function UserSearch({onClose}) {
    const [searchQuery, setSearchQuery] = useState("");
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleSearch = async () => {
        if (!searchQuery.trim()) {
            setError("Please enter a search term.");
            return;
        }
        setError("");
        setLoading(true);

        try {
            const users = await searchUsers(searchQuery);
            setResults(users);
        } catch (e) {
            console.error("Error searching for users:", e.message);
            setError("An error occurred while searching.");
        } finally {
            setLoading(false);
        }
    };

    const handleAction = async (userId, action) => {
        try {
            if (action === "add") {
                await sendFriendRequest(userId);
                // alert("Friend request sent!");
            } else if (action === "accept") {
                await respondToFriendRequest(userId, "accepted");
                // alert("Friend request accepted!");
            } else if (action === "revoke") {
                await respondToFriendRequest(userId, "canceled");
                // alert("Friend request revoked!");
            }
            // Refresh results to reflect the updated status
            handleSearch();
        } catch (e) {
            console.error(`Error performing action (${action}):`, e.message);
            alert("Failed to perform the action. Please try again.");
        }
    };


    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>User Search</h2>
                <div className="search-bar">
                    <input
                        type="text"
                        value={searchQuery}
                        placeholder="Search for users..."
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button onClick={handleSearch} disabled={loading}>
                        {loading ? "Searching..." : "Search"}
                    </button>
                </div>
                {error && <p className="error-message">{error}</p>}
                <div className="user-search-grid">
                    <div className="grid-header">
                        <span>Name</span>
                        <span>Username</span>
                        <span>Status</span>
                        <span>Actions</span>
                    </div>
                    {results.map((user) => (
                        <div key={user.id} className="grid-row">
                            <span>{user.display_name}</span>
                            <span>{user.username}</span>
                            <span>{user.relationship_status.replace("_", " ")}</span>
                            <div className="actions">
                                {user.relationship_status === "none" && (
                                    <button onClick={() => handleAction(user.id, "add")}>Add Friend</button>
                                )}
                                {user.relationship_status === "request_received" && (
                                    <button onClick={() => handleAction(user.id, "accept")}>Accept</button>
                                )}
                                {user.relationship_status === "request_sent" && (
                                    <button onClick={() => handleAction(user.friend_requests_id, "revoke")}>Revoke</button>
                                )}
                                {user.relationship_status === "friend" && (
                                    <button disabled>Friend</button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

export default UserSearch;
