import React, { useEffect, useState } from "react";

const Timer = ({ remainingTime, onTimeUp, isGameOver }) => {
    const [time, setTime] = useState(remainingTime); // Initialize with remaining time
    const [color, setColor] = useState("green"); // Initial color

    // Sync time state with the remainingTime prop when it changes
    useEffect(() => {
        setTime(remainingTime);
    }, [remainingTime]);

    useEffect(() => {
        // Stop the timer if the game is over or time reaches zero
        if (isGameOver || time <= 0) {
            if (time <= 0 && !isGameOver) {
                setColor("red");
                onTimeUp(); // Trigger time up event
            }
            return; // Stop the timer
        }

        // Update color based on remaining time
        if (time < 60) {
            setColor("red");
        } else if (time < 120) {
            setColor("yellow");
        } else {
            setColor("green");
        }

        // Countdown timer
        const interval = setInterval(() => {
            setTime((prev) => Math.max(prev - 1, 0));
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on component unmount or dependency change
    }, [time, isGameOver, onTimeUp]);

    // Format time as mm:ss
    const formatTime = (t) => {
        const minutes = Math.floor(t / 60);
        const seconds = t % 60;
        return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    };

    return (
        <div style={{ color, fontWeight: "bold", fontSize: "1.5rem" }}>
            {formatTime(time)}
        </div>
    );
};

export default Timer;
