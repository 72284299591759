import React, { useState, useEffect } from "react";
import StepsDisplay from "./StepsDisplay";
import HamburgerMenu from "./HamburgerMenu";
import StepStats from "./StepStats";
import missingImage from "../../images/default-placeholder.webp";
import PathDisplay from "./PathDisplay";

const GameBar = ({ targetActor, hints, onHint, steps, remainingTime,isGameOver,onTimeUp, gameId, onNewGame,handleMoveBack,onPreferencesClick, startingActor, remainingBackMoves , stepsLimit, gamePath, onHintActor, onHintMovie   }) => {
    const [isShrunk, setIsShrunk] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setIsShrunk(scrollY > 50); // Shrink when scrolled more than 50px
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleLogout = () => {
        document.cookie = "sessionId=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        window.location.href = "/";
    };

    // Exclude the last step for the main steps display
    const mainSteps = steps.slice(0, -1);
    const lastStep = steps[steps.length - 1];
    const stepCount = function () {
        let count = 0;
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === "movie") {
                count++;
            }
        }
        return count;
    }()
    return (
        <div className={`game-bar ${isShrunk ? "shrink" : ""}`}>
            {/* Starting Actor Section */}
            <div className="starting-actor-section">
                <h2 className="actor-name">{startingActor.id !== 0 ? startingActor.name : "Random Movies"}</h2>
                <img
                    src={startingActor.id !== 0  ? startingActor.profile_image?.url :missingImage}
                    alt={startingActor.id !== 0  ? startingActor.name : "Random Movies"}
                    className="actor-image"
                />
            </div>

            {/* Steps Section */}
            <div className={`steps-container ${isShrunk ? "shrink" : ""}`}>
                {/*<StepsDisplay steps={mainSteps} />*/}
                <PathDisplay path={gamePath} steps={steps} onHintActor={onHintActor} onHintMovie={onHintMovie} />
                <StepStats remainingTime={remainingTime} stepCount={stepCount} isGameOver={isGameOver} onTimeUp={onTimeUp} />
            </div>

            {/* Last Step Section */}
            {lastStep ? (
                <div
                    className={`last-step-section ${isShrunk ? "shrink" : ""} ${
                        stepCount === stepsLimit && remainingBackMoves > 0 ? "attention-effect" : ""
                    }`}
                >
                    <p>Last Choice</p>
                    <p className={`last-step-name ${isShrunk ? "shrink" : ""}`}>
                        {lastStep.title || lastStep.name}
                    </p>
                    <div
                        className={`last-step-image-container ${remainingBackMoves > 0 ? "glimmer-effect" : ""}`}
                        title={
                            remainingBackMoves === -1
                                ? "Unlimited moves back available"
                                : `${remainingBackMoves} move(s) back remaining`
                        }
                    >
                        <img
                            src={lastStep.image?.url || "default-placeholder.png"}
                            alt={lastStep.name || "Step"}
                            className={`last-step-image ${isShrunk ? "shrink" : ""}`}
                            onClick={handleMoveBack}
                        />
                        {remainingBackMoves > 0 && (
                            <span className="back-moves-count">
                    {remainingBackMoves === -1 ? "∞" : remainingBackMoves}
                </span>
                        )}
                    </div>
                </div>
            ) : (
                <div className={`last-step-section ${isShrunk ? "shrink" : ""}`}>
                    <p>Last Choice</p>
                    <p className={`last-step-name ${isShrunk ? "shrink" : ""}`}></p>
                    <img
                        src={missingImage}
                        alt={"Step"}
                        className={`last-step-image ${isShrunk ? "shrink" : ""}`}
                    />
                </div>
            )}

            {/* Timer Section */}

            {/* Target Actor Section */}
            <div className={`target-actor-section ${isShrunk ? "shrink" : ""}`}>
                <h2 className="actor-name">{targetActor.name}</h2>
                <div  className={`actor-image-container ${hints > 0 ? "glimmer-effect" : ""}`}
                      title={hints > 0 ? `${hints} hint(s) remaining` : "No hints available"}>
                    <img
                        src={targetActor.profile_image?.url || missingImage}
                        alt={targetActor.name || "Actor"}
                        className="actor-image"
                        onClick={onHint}
                    />
                    {hints > 0 && (
                        <span className="hints-count">{hints}</span>
                    )}
                </div>
            </div>

            {/* Hamburger Menu */}
            <HamburgerMenu
                gameId={gameId}
                onNewGame={onNewGame}
                onPreferencesClick={onPreferencesClick}
            />
        </div>
    );
};

export default GameBar;
