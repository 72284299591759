import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getQuickstart } from "../../api";

const LoadingScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const options = location.state?.options || {}; // Options passed from the initiating page
    const hasLoaded = useRef(false); // Prevent duplicate calls


    useEffect(() => {
        const loadGame = async () => {
            if (hasLoaded.current) return; // Exit if already loaded
            hasLoaded.current = true; // Mark as loaded
            try {
                const result = await getQuickstart(options); // Fetch or prepare game data
                if (result) {
                    navigate("/game", { state: { data: result } }); // Navigate to /game with loaded data
                } else {
                    alert("Failed to start the game.");
                    navigate("/"); // Redirect to main menu on failure
                }
            } catch (error) {
                console.error("Error during game loading:", error);
                alert("An error occurred. Please try again.");
                navigate("/"); // Redirect to main menu on error
            }
        };

        loadGame();
    }, [navigate, options]);

    return (
        <div className="loading-screen">
            <div className="spinner"></div>
            <p>Loading your game...</p>
        </div>
    );
};

export default LoadingScreen;
