import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import {getQuickstart, copyGame, fetchProfile, updateProfile, fetchNotifications, fetchChallengeRequests} from "../api";
import PreferencesModal from "./PreferencesModal";
import PlayHistoryModal from "./PlayHistoryModal";
import CustomGamesModal from "./CustomGamesModal";
import UserSearch from "./Social/UserSearch";
import SocialModal from "./Social/SocialModal";
import ChallengesMenu from "./Challenges/ChallengesMenu";
import socialIcon from "../images/social.svg";

const MainMenu = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // Loading stateconst [gameIdInput, setGameIdInput] = useState("");
    const [gameIdInput, setGameIdInput] = useState("");
    const [isGameIdValid, setIsGameIdValid] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    //setHintAmmount
    const [hintAmount, setHintAmount] = useState(3); // Default hint amount
    const [hintDuration, setHintDuration] = useState(10); // Default hint duration
    const [gameTimeLimit, setGameTimeLimit] = useState(5); // Default game time limit (in minutes)
    const [backMoves, setBackMoves] = useState(1);
    const [stepLimit, setStepLimit] = useState(6);
    const [requiredPick, setRequiredPick] = useState(false);
    const [startingActorId, setStartingActorId] = useState(0);// not used in qp
    const [randomActor, setRandomActor] = useState(false);
    const [decade, setDecade] = useState(0);
    const [omitPicks, setOmitPicks] = useState(true);
    // NEW: Add mediaType state (supports "MOVIE", "TVSHOW", "BOTH")
    const [mediaType, setMediaType] = useState("MOVIE");

    const [hintSort, setHintSort] = useState("date");
    const [movieSort, setMovieSort] = useState("date");
    const [showPreferences, setShowPreferences] = useState(false);
    const openPreferences = () => setShowPreferences(true);
    const closePreferences = () => setShowPreferences(false);
    const [friendNotificationCount, setFriendNotificationCount] = useState(0);
    //setShowUserSearch
    const [showSocialModal, setShowSocialModal] = useState(false);

    const [showCustomGameModal, setShowCustomGameModal] = useState(false);


    const [showModal, setShowModal] = useState(false);
    const playHistory = [

    ];

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const handlePlay = (id) => alert(`Play game ID: ${id}`);
    const handleShare = (id) => alert(`Share game ID: ${id}`);
    const handleInfo = (game) => alert(`Info: ${JSON.stringify(game)}`);
    //testing end//

    const closeCustomGameModal = () => {
        setShowCustomGameModal(false);
    };

    const handleSaveCustomGame = (gameData) => {
        console.log("Custom Game Data:", gameData);
        // Here you can add logic to send the gameData to your backend or start the game
        closeCustomGameModal();
    };
    // Fetch notifications on mount:
    useEffect(() => {
        async function loadNotifications() {
            try {
                const response = await fetchNotifications();
                const { notifications } = response;
                let friendRequestNotification = null;
                for (const notification of notifications) {
                    if (notification.type === "friendRequests") {
                        friendRequestNotification = notification;
                        break;
                    }
                }
                if (friendRequestNotification) {
                    setFriendNotificationCount(friendRequestNotification.count);
                }
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        }
        loadNotifications();
    }, []);



    useEffect(() => {
        // Check for the 'quickplay' query parameter
        const params = new URLSearchParams(window.location.search);
        if (params.get("quickplay") === "true") {
            handleQuickPlay();
        }

        const fetchPreferences = async () => {
            try {
                const profile = await fetchProfile();
                if(profile){
                    const quickplaySettings = profile.quickplayOptions ? profile.quickplayOptions : {};
                    // they may be a string and we need to convert them to a json object
                    if(typeof quickplaySettings === "string"){
                        quickplaySettings = JSON.parse(quickplaySettings);
                    }
                    setHintAmount(quickplaySettings.hintAmount || 3);
                    setHintDuration(quickplaySettings.hintDuration || 10);
                    setGameTimeLimit(quickplaySettings.gameTimeLimit || 5);
                    setBackMoves(quickplaySettings.backMoves || 1);
                    setStepLimit(quickplaySettings.stepLimit || 6);
                    setDecade(quickplaySettings.decade || 0);
                    setRandomActor(quickplaySettings.randomActor || false);
                    setRequiredPick(quickplaySettings.requiredPick || false);
                    setOmitPicks(quickplaySettings.omitPicks || true);
                    setMediaType(quickplaySettings.mediaType || "MOVIE");

                }

            } catch (error) {
                console.error("Error fetching preferences:", error);
            }
        };

        fetchPreferences();

    }, [window.location.search]);

    const handleQuickPlay = async (e) => {
        const secGameTimeLimit = gameTimeLimit * 60;
        e?.preventDefault();
        // Include mediaType in the options passed to QuickStart
        navigate("/loading", {
            state: {
                options: {
                    hintDuration: hintDuration,
                    secGameTimeLimit: secGameTimeLimit,
                    backMoves: backMoves,
                    stepLimit: stepLimit,
                    decade: decade,
                    randomActor: randomActor,
                    hintAmount: hintAmount,
                    requiredPick: requiredPick,
                    omitPicks: omitPicks,
                    mediaType: mediaType  // NEW quickstart parameter!
                },
            },
        });
    };

    const handleCopyGame = async (e) => {
        e.preventDefault();
        try {
            const result = await copyGame(gameIdInput); // Call backend API to copy game
            if (result) {
                navigate("/game", { state: { data: result } });
            } else {
                alert("Game not found or failed to copy.");
            }
        } catch (error) {
            console.error("Error during game copy:", error);
            alert("An error occurred. Please try again.");
        }
    };


    const handleGameIdChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Ensure only numeric input
            setGameIdInput(value);
            setIsGameIdValid(value.length > 0); // Validate if input is non-empty
        }

    };

    const handleOptionsSave = async () => {
        const quickplayOptions = {
            hintAmount,
            hintDuration,
            gameTimeLimit,
            backMoves,
            stepLimit,
            decade,
            randomActor,
            requiredPick,
            omitPicks,
            mediaType
        };

        try {
            const updatedPreferences = {
                quickplayOptions,
            };
            await updateProfile(updatedPreferences);
            alert("Settings saved!");
        } catch (error) {
            console.error("Failed to save settings:", error);
        }
        setShowOptions(false); // Close the modal
    };


    //openSocialModal
    const openSocialModal = () => {
        setFriendNotificationCount(0);
        setShowSocialModal(true);
    };

    return (
        <div style={{ position: "relative" }}>
        {/* Header elements (Profile dropdown, Social button, etc.) */}
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
            <div className="profile-dropdown">
                <ProfileDropdown onPreferencesUpdate={openPreferences} />
                {showPreferences && <PreferencesModal onClose={closePreferences} onSave={() => {}} />}
            </div>
            <div className="social-button-container">
                <button onClick={openSocialModal} className="social-button">
                    <img src={socialIcon} alt="Social" />
                    {friendNotificationCount > 0 && <span className="notification-badge">{friendNotificationCount}</span>}
                </button>
            </div>
        </div>

               {/* Main container for buttons on the left */}
               <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start", // align buttons to the top
                height: "100vh",
                paddingRight: "20px", // space from the right edge
            }}
        >
            <div className="main-menu" style={{ flex: 1 }}>
                <h1 style={{ color: "var(--primary-color)" }}>TV Show Update v1.0</h1>
                <button onClick={handleQuickPlay}>Quick Play</button>
                <button onClick={() => setShowOptions(true)}>Quick Play Options</button>
                <button onClick={() => navigate("/custom-game")}>Create Custom Game</button>
                <input
                        type="text"
                        value={gameIdInput}
                        onChange={handleGameIdChange}
                        placeholder="Enter Game ID"
                        style={{ padding: "5px", fontSize: "16px", width: "200px", marginTop: "10px", marginBottom: "1px" }}
                    />
                <button
                        onClick={handleCopyGame}
                        disabled={!isGameIdValid}
                        style={{
                            padding: "5px 5px",
                            marginLeft: "10px",
                            marginTop: "1px",
                            backgroundColor: isGameIdValid ? "var(--primary-color)" : "gray",
                            color: "white",
                            border: "none",
                            cursor: isGameIdValid ? "pointer" : "not-allowed",
                        }}
                    >
                        Play Game ID
                    </button>
                <div style={{ marginTop: "20px" }}>

                    <div>
                        <button onClick={() => setShowModal(true)}>View Play History</button>
                        {showModal && (
                            <PlayHistoryModal
                                games={playHistory}
                                onClose={() => setShowModal(false)}
                                onPlay={handlePlay}
                                onShare={handleShare}
                                onInfo={handleInfo}
                            />
                        )}
                    </div>
                    <div>
                        <button onClick={() => setShowCustomGameModal(true)}>View Custom Games</button>
                        {showCustomGameModal && (
                            <CustomGamesModal onClose={closeCustomGameModal} onSave={handleSaveCustomGame} />
                        )}
                    </div>
                    {showSocialModal && <SocialModal onClose={() => setShowSocialModal(false)} />}
                </div>
            </div>
        </div>

          {/* ChallengesMenu for large screens: fixed to the middle right */}
        {!isSmallScreen && (
            <div
                className="challenges-container"
                style={{
                    position: "fixed",
                    right: "20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "30%",
                }}
            >
                <ChallengesMenu />
            </div>
        )}

        {/* For small screens: ChallengesMenu at the bottom */}
        {isSmallScreen && (
            <div className="challenges-bottom" style={{ marginTop: "20px" }}>
                <ChallengesMenu />
            </div>
        )}

            {/* Loading Overlay */}
            {loading && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <p className="loading-text">Creating your game... Please wait.</p>
                </div>
            )}
            {showOptions && (
                <div className="modal-overlay game-options">
                    <div className="modal game-options">
                        <h2>Game Options</h2>
                        <div className="option">
                            <label>Hint Amount:</label>
                            <input
                                type="number"
                                value={hintAmount}
                                onChange={(e) => setHintAmount(e.target.value)}
                                min="0"
                                max="30"
                            />
                        </div>
                        
                        <div className="option">
                            <label>Hint Duration (seconds):</label>
                            <input
                                type="number"
                                value={hintDuration}
                                onChange={(e) => setHintDuration(e.target.value)}
                                min="5"
                                max="30"
                            />
                        </div>
                        <div className="option">
                            <label>Game Time Limit (minutes):</label>
                            <input
                                type="number"
                                value={gameTimeLimit}
                                onChange={(e) => setGameTimeLimit(e.target.value)}
                                min="1"
                                max="30"
                            />
                        </div>
                        <div className="option">
                            <label>Back Steps:</label>
                            <input
                                type="number"
                                value={backMoves}
                                onChange={(e) => setBackMoves(e.target.value)}
                                min="-1"
                                max="50"
                            />
                        </div>
                        <div className="option">
                            <label>Steps Limit:</label>
                            <input
                                type="number"
                                value={stepLimit}
                                onChange={(e) => setStepLimit(e.target.value)}
                                min="3"
                                max="100"
                            />
                        </div>
                        <div className="option">
                            <label>Decade:</label>
                            <select
                                value={decade}
                                onChange={(e) => {
                                    setDecade(e.target.value);
                                    if (e.target.value !== "0") {
                                        setRandomActor(false); // Disable Random Actor when a decade is selected
                                    }
                                }}
                                // disabled={randomActor} // Disable if Random Actor is selected
                            >
                                <option value="0">
                                    Select a decade
                                </option>
                                <option value="1930">1930s</option>
                                <option value="1940">1940s</option>
                                <option value="1950">1950s</option>
                                <option value="1960">1960s</option>
                                <option value="1970">1970s</option>
                                <option value="1980">1980s</option>
                                <option value="1990">1990s</option>
                                <option value="2000">2000s</option>
                                <option value="2010">2010s</option>
                                <option value="2020">2020s</option>
                            </select>
                        </div>
                        <div className="option">
                            <label>Starting Random Actor: (cannot be used with Decade)</label>
                            <input
                                type="checkbox"
                                checked={randomActor}
                                onChange={(e) => {
                                    setRandomActor(e.target.checked);
                                    if (e.target.checked) {
                                        setDecade("0"); // Reset Decade when Random Actor is selected
                                    }
                                }}
                            />
                        </div>
                        {/* NEW: Media Type Selection */}
                        <div className="option">
                            <label>Media Type:</label>
                            <select value={mediaType} onChange={(e) => setMediaType(e.target.value)}>
                                <option value="MOVIE">Movie</option>
                                <option value="TVSHOW">TV Show</option>
                                <option value="BOTH">Both</option>
                            </select>
                        </div>
                        {/*//setRequiredPick*/}
                        <div className="option">
                            <label>Random Required Pick (!! May create Unbeatable Game !!)</label>
                            <input
                                type="checkbox"
                                checked={requiredPick}
                                onChange={(e) => {
                                    setRequiredPick(e.target.checked);
                                }}
                            />
                        </div>
                        {/*omitPicks*/}
                        <div className="option">
                            <label>Omit Picks (Previous picks are not available)</label>
                            <input
                                type="checkbox"
                                checked={omitPicks}
                                onChange={(e) => {
                                    setOmitPicks(e.target.checked);
                                }}
                            />
                        </div>

                        <div className="modal-actions">
                            <button onClick={handleOptionsSave}>Save</button>
                            <button onClick={() => setShowOptions(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}



        </div>
    );
};

export default MainMenu;