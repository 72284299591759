import React, { useEffect, useState } from "react";
import { fetchLeaderBoard } from "../../api"; // Assumes an API function that takes (challengeId, limit)
  
const LeaderBoardModal = ({ challengeId, onClose }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const limit = 5; // top 5 players

    useEffect(() => {
        async function loadLeaderBoard() {
            try {
                const response = await fetchLeaderBoard(challengeId, limit);
                if (response.error) {
                    setError(response.error);
                } else {
                    setData(response);
                }
            } catch (err) {
                console.error("Error fetching leaderboard:", err);
                setError("Failed to fetch leaderboard.");
            }
        }
        loadLeaderBoard();
    }, [challengeId]);

    if (error) {
        return (
            <div className="leaderboard-modal-overlay">
                <div className="leaderboard-modal-content">
                    <p className="leaderboard-error">{error}</p>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    }

    if (!data) {
        return (
            <div className="leaderboard-modal-overlay">
                <div className="leaderboard-modal-content">
                    <p>Loading...</p>
                </div>
            </div>
        );
    }

    // data is expected to include:
    // data.gameDetails: { targetActor, startingActor, score, steps, remaining_time }
    // data.player: { score, rank } (optional, only if the player has a recorded rank)
    // data.leaderBoard: [ { rank, username, score }, ... ]
    return (
        <div className="leaderboard-modal-overlay">
            <div className="leaderboard-modal-content">
                <h2>Leader Board</h2>
                <div className="leaderboard-game-details">
                    <h3>Game Details</h3>
                    <p><strong>Target Actor:</strong> {data.gameDetails.targetActor?.name || "N/A"}</p>
                    <p><strong>Starting Actor:</strong> {data.gameDetails.startingActor?.name || "N/A"}</p>
                </div>

                {data.player && (
                    <div className="leaderboard-player-rank">
                        <h3>Your Score &amp; Rank</h3>
                        <p><strong>Score:</strong> {data.player.score}</p>
                        <p><strong>Rank:</strong> {data.player.rank}</p>
                    </div>
                )}

                <div className="leaderboard-grid">
                    <h3>Top 5 Players</h3>
                    <div className="leaderboard-grid-header">
                        <span>Rank</span>
                        <span>Name</span>
                        <span>Score</span>
                    </div>
                    {data.leaderBoard && data.leaderBoard.map((item, index) => (
                        <div key={index} className="leaderboard-grid-row">
                            <span>{item.rank}</span>
                            <span>{item.username}</span>
                            <span>{item.score}</span>
                        </div>
                    ))}
                </div>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default LeaderBoardModal;