import React, { useEffect, useState } from "react";
import {copyGame, fetchHistory} from "../api"; // API call to fetch custom games
import "../CustomGamesModal.css";
import { useNavigate } from "react-router-dom";

const CustomGamesModal = ({ onClose, onPlayGame, onShareGame }) => {
    const [customGames, setCustomGames] = useState([]);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await fetchHistory('custom'); // Fetch custom games
                if (response.error) {
                    setError(response.error);
                } else {
                    let formatedResponse = parse(response);
                    setCustomGames(formatedResponse.games || []);
                }
            } catch (err) {
                console.error("Failed to fetch play history:", err);
                setError("An error occurred while fetching play history.");
            }
        };

        fetchGames();
    }, []);
    const parse = (response) => {
        // // returns respones but with addions for some calculations
        // // for eahc game we check if it is a win depeoindg oin if the has a gameover and if the time is greater than 0
        // response.games.forEach(game => {
        //     game.stats.win = game.stats.gameOver && game.stats.remaining_time > 0;
        //     game.stats.remaining_time = game.stats.gameOver ? game.stats.remaining_time : 0;
        // });

        return response;

    }

    const handlePlay = async (gameId) => {
        try {
            const result = await copyGame(gameId); // Call backend API to copy game
            if (result) {
                navigate("/game", { state: { data: result } });
            } else {
                alert("Game not found or failed to copy.");
            }
        } catch (error) {
            console.error("Error during game copy:", error);
            alert("An error occurred. Please try again.");
        }
    }

    //Share
    const handleShare = (id) => alert(`WIP Share game ID: ${id}`);

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Custom Games</h2>
                {error && <p className="error-message">{error}</p>}
                <div className="custom-games-grid">
                    <div className="grid-header">
                        <span>ID</span>
                        <span>Target Actor</span>
                        <span>Starting Actor</span>
                        <span>Actions</span>
                    </div>
                    {customGames.map((game) => (
                        <div key={game.id} className="grid-row">
                            <span>{game.id}</span>
                            <span>{game.targetActor.name}</span>
                            <span>{game.startingActor?.name || "N/A"}</span>
                            <div className="actions">
                                <button onClick={() => handlePlay(game.id)}>Play</button>
                                <button onClick={() => handleShare(game.id)}>Share</button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="modal-actions">
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default CustomGamesModal;