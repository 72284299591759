import React, { useState } from "react";
import ActorSelectionModal from "../components/ActorSelectionModal";
import { createCustomGame, copyGame } from "../api";
import missingImage from "../images/default-placeholder.webp";
import { useNavigate } from "react-router-dom";
import "../CustomGamePage.css";

const CustomGamePage = () => {
    const [showActorModal, setShowActorModal] = useState(false);
    const [actorType, setActorType] = useState(null); // 'starting' or 'target'
    const [startingActor, setStartingActor] = useState(null);
    const [targetActor, setTargetActor] = useState(null);
    const [degreesLimit, setDegreesLimit] = useState(6); // Default degrees limit
    const [timeLimit, setTimeLimit] = useState(5); // Default time limit in minutes
    const [backMoves, setBackMoves] = useState(1);
    const [randomActor, setRandomActor] = useState(false);
    const [decade, setDecade] = useState(0);
    const [hintAmount, setHintAmount] = useState(1); // Default hint amount
    const [hintDuration, setHintDuration] = useState(10); // Default hint duration
    const navigate = useNavigate();

    const openActorModal = (type) => {
        setActorType(type);
        setShowActorModal(true);
    };

    const closeActorModal = () => {
        setShowActorModal(false);
        setActorType(null);
    };

    const handleActorSelect = (actor) => {
        if (actorType === "starting") {
            setStartingActor(actor);
            setTargetActor(null); // Reset target actor if starting actor changes
        } else if (actorType === "target") {
            setTargetActor(actor);
        }
        closeActorModal();
    };

    const handleSave = async (playAfterSave = false) => {
        const secGameTimeLimit = timeLimit * 60;
        try {
            const game = await createCustomGame({
                secGameTimeLimit: secGameTimeLimit,
                startingActorId: startingActor?.id || 0,
                targetActorId: targetActor?.id || 0,
                stepLimit: degreesLimit,
                backMoves: backMoves,
                randomActor: randomActor,
                decade: decade,
                hintAmount: hintAmount,
                hintDuration: hintDuration

            });

            if (playAfterSave) {
                const copiedGame = await copyGame(game.gameId);
                if (copiedGame) {
                    navigate("/game", { state: { data: copiedGame } });
                } else {
                    alert("Failed to start the game. Please try again.");
                }
            }
        } catch (error) {
            console.error("Error during game creation or play:", error);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <div className="custom-game-page">
            <h1>Create Your Custom Game</h1>
            <div className="custom-game-content">
                {/* Starting Actor Section */}
                <div className="actor-selection">
                    <h3>Starting Actor</h3>
                    <div className="option-row">
                        {/* Random Actor Checkbox */}
                        <div className="option">
                            <label>Random Actor:</label>
                            <input
                                type="checkbox"
                                checked={randomActor}
                                onChange={(e) => {
                                    setRandomActor(e.target.checked);
                                    if (e.target.checked) {
                                        setDecade("0"); // Reset decade
                                        setStartingActor({ id: 0 }); // Reset starting actor
                                    }
                                }}
                            />
                        </div>

                        {/* Decade Dropdown */}
                        <div className="option">
                            <label>Decade:</label>
                            <select
                                value={decade}
                                onChange={(e) => {
                                    setDecade(e.target.value);
                                    setRandomActor(false); // Always disable random actor when a decade is selected
                                    setStartingActor({ id: 0 }); // Reset starting actor
                                }}
                            >
                                <option value="0">Select a decade</option>
                                <option value="1930">1930s</option>
                                <option value="1940">1940s</option>
                                <option value="1950">1950s</option>
                                <option value="1960">1960s</option>
                                <option value="1970">1970s</option>
                                <option value="1980">1980s</option>
                                <option value="1990">1990s</option>
                                <option value="2000">2000s</option>
                                <option value="2010">2010s</option>
                                <option value="2020">2020s</option>
                            </select>
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            openActorModal("starting");
                            setRandomActor(false); // Disable random actor
                            setDecade("0"); // Reset decade
                        }}
                    >
                        Select Starting Actor
                    </button>
                    {startingActor?.id !== 0 && (
                        <div>
                            <img
                                src={startingActor?.profile_image?.url || missingImage}
                                alt={startingActor?.name || ""}
                            />
                            <p>{startingActor?.name || "" }</p>
                        </div>
                    )}
                </div>

                {/* Target Actor Section */}
                <div className="actor-selection">
                    <h3>Target Actor</h3>
                    <div className="option">
                        <label>Random Actor:</label>
                        <input
                            type="checkbox"
                            checked={targetActor?.id === 0}
                            onChange={(e) => {
                                setTargetActor(e.target.checked ? { id: 0 } : null); // Set target actor to random or clear
                            }}
                        />
                    </div>
                    <button
                        onClick={() => openActorModal("target")}
                        disabled={startingActor?.id === undefined || startingActor?.id === null}

                    >
                        Select Target Actor
                    </button>
                    {targetActor?.id !== 0 && (
                        <div>
                            <img
                                src={targetActor?.profile_image?.url || missingImage}
                                alt={targetActor?.name || ''}
                            />
                            <p>{targetActor?.name || ""}</p>
                        </div>
                    )}
                    {!startingActor?.id && <p>Please select a starting actor first.</p>}
                </div>

                {/* Settings Section */}
                <div className="settings-section">
                    <h3>Basic Settings</h3>
                    <label>Back Steps:</label>
                    <input
                        type="number"
                        value={backMoves}
                        onChange={(e) => setBackMoves(e.target.value)}
                        min="-1"
                        max="50"
                    />
                    <label>
                        Steps Limit:
                        <input
                            type="number"
                            value={degreesLimit}
                            onChange={(e) => setDegreesLimit(Number(e.target.value))}
                            min="1"
                            max="10"
                        />
                    </label>
                    <label>
                        Time Limit (minutes):
                        <input
                            type="number"
                            value={timeLimit}
                            onChange={(e) => setTimeLimit(Number(e.target.value))}
                            min="1"
                            max="30"
                        />
                    </label>
                    <label>Hint Amount:</label>
                    <input
                        type="number"
                        value={hintAmount}
                        onChange={(e) => setHintAmount(e.target.value)}
                        min="0"
                        max="30"
                    />
                    <label>Hint Duration (seconds):</label>
                    <input
                        type="number"
                        value={hintDuration}
                        onChange={(e) => setHintDuration(e.target.value)}
                        min="5"
                        max="30"
                    />
                </div>

                {/* Action Buttons */}
                <div className="action-buttons">
                    <button onClick={() => handleSave(false)}>Save</button>
                    <button onClick={() => handleSave(true)}>Save and Play</button>
                    <button onClick={() => navigate("/menu")}>Cancel</button>
                </div>
            </div>

            {/* Actor Selection Modal */}
            {showActorModal && (
                <ActorSelectionModal
                    onClose={closeActorModal}
                    onActorSelect={handleActorSelect}
                    actorType={actorType}
                    startingActorId={startingActor?.id || null}
                />
            )}
        </div>
    );
};

export default CustomGamePage;
