import React, { useState } from "react";
import ProfileDropdown from "../ProfileDropdown";

const HamburgerMenu = ({ gameId, onNewGame, onPreferencesClick }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen((prev) => !prev);
    const handleEndGame = () => {
        // Redirect user to the main menu
        window.location.href = "/menu";
    };
    return (
        <div className="hamburger-menu">
            <div className="menu-icon" onClick={toggleMenu}>
                ☰
            </div>
            {isMenuOpen && (
                <div className="dropdown-menu">
                    <ProfileDropdown onPreferencesUpdate={onPreferencesClick} />
                    <button onClick={onNewGame}>New Game</button>
                    <button onClick={handleEndGame}>End Game</button>
                    <p>Game ID: {gameId}</p>
                </div>
            )}
        </div>
    );
};

export default HamburgerMenu;
