

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import MainMenu from "./components/MainMenu";
import Game from "./components/GameQP/Game";
import LoadingScreen from "./components/GameQP/LoadingScreen";
import CustomGamePage from "./components/CustomGamePage";


const App = () => {
    return (
        <Router>
            <Helmet>
                {/* Default Meta Tags */}
                <title>Bacon: The Movie Connection Game</title>
                <meta name="description" content="Challenge your cinematic knowledge and connect actors through movies in this thrilling game of Bacon!" />
                <meta name="keywords" content="Bacon game, movie game, actor connections, cinematic trivia, six degrees of Kevin Bacon, Hollywood, movie buffs" />
                <meta name="author" content="RogerV" />
                <meta property="og:title" content="Bacon: The Movie Connection Game" />
                <meta property="og:description" content="Connect actors through movies in this fun and challenging game inspired by Six Degrees of Kevin Bacon." />
                <meta property="og:image" content="https://moviebacon.com/images/default-placeholder.webp" />
                <meta property="og:url" content="https://moviebacon.com" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Bacon: The Movie Connection Game" />
                <meta name="twitter:description" content="Connect actors through movies in this thrilling game!" />
                <meta name="twitter:image" content="https://moviebacon.com/images/default-placeholder.webp" />
            </Helmet>
            <Routes>
                <Route
                    path="/"
                    element={
                        <>
                            <Helmet>
                                <title>Login - Bacon</title>
                                <meta name="description" content="Log in to Bacon and start playing the movie connection game!" />
                            </Helmet>
                            <Login />
                        </>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <>
                            <Helmet>
                                <title>Register - Bacon</title>
                                <meta name="description" content="Create an account to join the Bacon movie connection game!" />
                            </Helmet>
                            <Register />
                        </>
                    }
                />
                <Route
                    path="/menu"
                    element={
                        <>
                            <Helmet>
                                <title>Main Menu - Bacon</title>
                                <meta name="description" content="Choose your game options and start playing Bacon!" />
                            </Helmet>
                            <MainMenu />
                        </>
                    }
                />
                <Route
                    path="/game"
                    element={
                        <>
                            <Helmet>
                                <title>Game - Bacon</title>
                                <meta name="description" content="Play the Bacon movie connection game and test your cinematic knowledge!" />
                            </Helmet>
                            <Game />
                        </>
                    }
                />
                <Route path="/loading" element={<LoadingScreen />} />
                <Route path="/custom-game" element={<CustomGamePage />} />
            </Routes>
        </Router>
    );
};

export default App;
