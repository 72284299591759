import React, { useState, useEffect, useRef } from "react";
import StepsDisplay from "./StepsDisplay";
import LeaderBoardModal from "../Challenges/LeaderBoardModal";
import missingImage from "../../images/default-placeholder.webp";

const GameStatsPopup = ({ stats, steps, onClose, onPlayAgain, onReplay, onShare }) => {
    const [copiedTime, setCopiedTime] = useState(stats.remaining_time || 0);
    const [currentScore, setCurrentScore] = useState(0);
    const [stepItems, setStepItems] = useState([]);
    const [bonusList, setBonusList] = useState([]); // Track displayed bonuses
    const [floatingScores, setFloatingScores] = useState([]); // Track floating animations
    const [animatedSteps, setAnimatedSteps] = useState([]);
    const [leaderBoardOpen, setLeaderBoardOpen] = useState(false); // New state for LeaderBoardModal
    const effectRan = useRef(false);
    const scorePath = Array.isArray(stats.scorePath) ? stats.scorePath : [];
    const scoreTime = stats.scoreTime || 0;
    const win = stats.win ?? true;
    const scoreDisplayThreshold = 2; // Threshold for floating animation

    useEffect(() => {
        if (effectRan.current) return;
        effectRan.current = true;

        const animateTimeScore = () => {
            return new Promise((resolve) => {
                let localTime = scoreTime;
                const interval = setInterval(() => {
                    if (localTime > 0) {
                        updateScore(3); // Increment score
                        localTime -= 1;
                    } else {
                        clearInterval(interval);
                        resolve();
                    }
                }, 10);
            });
        };

        const animateSteps = () => {
            return new Promise((resolve) => {
                scorePath.forEach((step, index) => {
                    setTimeout(() => {
                        setAnimatedSteps((prev) => [...prev, step]);
                        if (step.value !== 0) {
                            updateScore(step.value);
                        }
                        if (index === scorePath.length - 1) resolve();
                    }, 500 * (index + 1)); // Delay per step
                });
            });
        };

        const animateBonuses = () => {
            return new Promise((resolve) => {
                if (!Array.isArray(stats.bonuses) || stats.bonuses.length === 0) {
                    // No bonuses, resolve immediately.
                    return resolve();
                }
                stats.bonuses.forEach((bonus, index) => {
                    setTimeout(() => {
                        updateScore(bonus.value); // Add bonus points
                        setBonusList((prev) => [...prev, bonus]); // Add bonus to list
                        if (index === stats.bonuses.length - 1) resolve();
                    }, 500 * (index + 1));
                });
            });
        };

        const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        const runAnimations = async () => {
            if(!win){
                return;
            }
            await animateTimeScore();
            await pause(200);
            await animateBonuses();
            await pause(1000); // 1-second pause
            await animateSteps();
        };

        runAnimations();
    }, [steps, stats]);

    // Update the score and trigger floating animation
    const updateScore = (value) => {
        setCurrentScore((prev) => Math.max(prev + value, 0));
        if (Math.abs(value) >= scoreDisplayThreshold) {
            setFloatingScores((prev) => [
                ...prev,
                { id: Date.now(), value } // Unique ID for animation tracking
            ]);
        }
    };

    const gameOverReason = () => {
        let gameOverText = "Game Over: ";
        if (stats.lose_condition === "time_out") return gameOverText + "You ran out of time.";
        if (stats.lose_condition === "step_limit") return gameOverText + "You reached the step limit, Game Over!";
        if (stats.lose_condition === "requirements_not_met") return gameOverText + "You did not match the required pick for the step.";
        const stepLength = steps.filter((step) => step.type === "media").length;
        return `You made it in ${stepLength} degrees of separation! With ${stats.remaining_time} seconds left!`;
    };

    const returnToMenu = () => {
        window.location.href = "/menu";
    };

    return (
        <div className="game-stats-popup">
            <h2>{gameOverReason()}</h2>
            <div className="score-display">
                <h3>
                    Score: {currentScore}{" "}
                    {stats.your_rank && (
                        <span className="your-rank">(Your Rank: {stats.your_rank})</span>
                    )}
                    {/* Floating animations */}
                    <div className="floating-scores">
                        {floatingScores.map((floatScore) => (
                            <div
                                key={floatScore.id}
                                className={`floating-score ${floatScore.value > 0 ? "positive" : "negative"}`}
                            >
                                {floatScore.value > 0 ? `+${floatScore.value}` : `${floatScore.value}`}
                            </div>
                        ))}
                    </div>
                </h3>
            </div>

            <StepsDisplay steps={animatedSteps} />
            <div className="bonuses-list">
                {bonusList.map((bonus, index) => (
                    <div key={index} className="bonus-item">
                        <p>{`Bonus: ${bonus.type} (+${bonus.value} points)`}</p>
                    </div>
                ))}
            </div>
            <div className="stats-actions">
                <button onClick={onShare}>Share</button>
                <button onClick={onReplay}>Replay</button>
                <button onClick={onPlayAgain}>New Game</button>
                <button onClick={returnToMenu}>Home</button>
                {stats.your_rank && (
                    <button onClick={() => setLeaderBoardOpen(true)}>Leader Board</button>
                )}
            </div>
            {leaderBoardOpen && stats.challenge_id && (
                <LeaderBoardModal 
                    challengeId={stats.challenge_id} 
                    onClose={() => setLeaderBoardOpen(false)}
                />
            )}
        </div>
    );
};

export default GameStatsPopup;