import React, { useEffect, useState } from "react";
import missingImage from "../../images/default-placeholder.webp";

const genreMapping = {
    28: "Action",
    12: "Adventure",
    16: "Animation",
    35: "Comedy",
    80: "Crime",
    99: "Documentary",
    18: "Drama",
    10751: "Family",
    14: "Fantasy",
    36: "History",
    27: "Horror",
    10402: "Music",
    9648: "Mystery",
    10749: "Romance",
    878: "Science Fiction",
    10770: "TV Movie",
    53: "Thriller",
    10752: "War",
    37: "Western",
};

const PathDisplay = ({ path, steps, onHintActor, onHintMovie }) => {
    const [filledSteps, setFilledSteps] = useState([]);

    useEffect(() => {
        setFilledSteps(steps);
    }, [steps]);

    const getGenreName = (genreId) => genreMapping[genreId] || false;

    return (
        <div className="path-display">
            {path.map((spot, index) => {
                const step = filledSteps[index];
                const isFilled = step !== undefined;
                const isMandatory = spot.id !== -1;
                const genreBonus = spot.genreBonus && getGenreName(spot.genreBonus);

                return (
                    <div
                        key={index}
                        className={`path-spot ${spot.type === "actor" ? "actor-spot" : "movie-spot"} ${
                            isFilled ? "filled" : ""
                        } ${(genreBonus && spot.type !== "actor") ? "genre-bonus" : ""} ${
                            isMandatory && !isFilled ? "mandatory" : ""
                        }`}
                    >
                        {isFilled ? (
                            <img
                                src={step.image?.url || missingImage}
                                alt={step.type === "movie" ? "Movie" : "Actor"}
                                title={step.name || ""}
                                onError={(e) => (e.target.src = missingImage)}
                            />
                        ) : isMandatory ? (
                            <div className="mandatory-info">
                                <img
                                    src={spot.image?.url || missingImage}
                                    alt={spot.type === "movie" ? "Movie" : "Actor"}
                                    title={spot.type === "movie" ? spot.title : spot.name }
                                    className="grayscale"
                                    onError={(e) => (e.target.src = missingImage)}
                                    onClick={() =>
                                        spot.type === "actor"
                                            ? onHintActor(spot.id)
                                            : onHintMovie(spot.id)
                                    }

                                />
                                {/*<p>{spot.name}</p>*/}
                            </div>
                        ) : (
                            <div className="path-info">
                                {genreBonus && spot.type !== "actor" && (
                                    <p className="bonus-label">{"Genre Bonus " + genreBonus}</p>
                                )}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default PathDisplay;
