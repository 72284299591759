import React, { useEffect, useState } from "react";
import "../PlayHistoryModal.css";
import { useNavigate } from "react-router-dom";
import { copyGame, fetchHistory } from "../api"; // Import the API function
import IssueChallenge from "./Challenges/IssueChallenge";

const PlayHistoryModal = ({ games, onClose, onPlay, onShare, onInfo }) => {
    const [history, setHistory] = useState([]);
    const [error, setError] = useState("");
    const [issueChallengeDetails, setIssueChallengeDetails] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const loadHistory = async () => {
            try {
                const response = await fetchHistory("regular"); // Fetch regular games
                if (response.error) {
                    setError(response.error);
                } else {
                    let formatedResponse = parse(response);
                    setHistory(formatedResponse.games || []);
                }
            } catch (err) {
                console.error("Failed to fetch play history:", err);
                setError("An error occurred while fetching play history.");
            }
        };

        loadHistory();
    }, []);

    const parse = (response) => {
        // returns response but with additions for some calculations
        // for each game we check if it is a win depending on if the game is over and if the remaining time is greater than 0
        response.games.forEach(game => {
            game.stats.win = game.stats.gameOver && game.stats.remaining_time > 0;
            game.stats.remaining_time = game.stats.gameOver ? game.stats.remaining_time : 0;
        });

        return response;
    };

    const handlePlay = async (gameId) => {
        try {
            const result = await copyGame(gameId); // Call backend API to copy game
            if (result) {
                navigate("/game", { state: { data: result } });
            } else {
                alert("Game not found or failed to copy.");
            }
        } catch (error) {
            console.error("Error during game copy:", error);
            alert("An error occurred. Please try again.");
        }
    };

    // Updated handleShare: open IssueChallenge modal by setting the challenge details
    const handleShare = (id, stats) => {
        setIssueChallengeDetails({
            parentGameId: id,
            score: stats.score,
            settings: stats.settings,
        });
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Play History</h2>
                {error && <p className="error-message">{error}</p>}
                <div className="history-grid">
                    {history.length > 0 ? (
                        history.map((game) => (
                            <div key={game.id} className="history-item">
                                <div className="actor-info">
                                    <p><strong>Target:</strong> {game.targetActor?.name || "Unknown"}</p>
                                    <p><strong>Starting:</strong> {game.startingActor?.name || "Random"}</p>
                                </div>
                                <div className="game-stats">
                                    <p><strong>Win:</strong> {game.stats.win ? "Yes" : "No"}</p>
                                    <p><strong>Score:</strong> {game.stats.score || "0"}</p>
                                    <p><strong>Steps:</strong> {game.stats.steps || "N/A"}</p>
                                    <p><strong>Time:</strong> {game.stats.remaining_time || "N/A"}s</p>
                                </div>
                                <div className="actions">
                                    <button onClick={() => handlePlay(game.id)}>Play</button>
                                    <button onClick={() => handleShare(game.parentId, game.stats)}>
                                        Share
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No play history found.</p>
                    )}
                </div>
                <button onClick={onClose}>Close</button>
            </div>

            {/* Conditionally render IssueChallenge modal */}
            {issueChallengeDetails && (
                <IssueChallenge 
                    gameDetails={issueChallengeDetails} 
                    onClose={() => setIssueChallengeDetails(null)}
                />
            )}
        </div>
    );
};

export default PlayHistoryModal;
