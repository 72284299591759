import React, { useMemo } from "react";
import missingImage from "../images/default-placeholder.webp";

const SelectionGrid = ({ items, onItemClick, onPreviewClick }) => {
    // Sort items by popularity in descending order
    const sortedItems = useMemo(() => {
        return [...items].sort((a, b) => b.popularity - a.popularity);
    }, [items]);

    return (
        <div className="selection-grid-container">
            <div className="selection-grid">
                {sortedItems.map((item) => (
                    <div key={item.id} className="selection-grid-item">
                        <img
                            src={item.profile_image?.url || missingImage}
                            alt={item.name}
                            onError={(e) => (e.target.src = missingImage)} // Replace with default image on error
                        />
                        <h3>{item.name}</h3>
                        <p>Popularity: {item.popularity.toFixed(2)}</p>
                        <div className="selection-actions">
                            <button onClick={() => onPreviewClick(item)}>Preview</button>
                            <button onClick={() => onItemClick(item)}>Select</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SelectionGrid;