import React, {useEffect, useRef, useState} from "react";
import missingImage from "../../images/default-placeholder.webp";

const StepsDisplay = ({ steps }) => {
    const containerRef = useRef(null);
    const [scale, setScale] = useState(1);

    useEffect(() => {
        const adjustScale = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.clientWidth;
                const totalItemWidth = steps.length * 85; // 80px width + 5px gap
                const newScale = containerWidth / totalItemWidth;

                // Ensure the scale does not exceed 1 (no upscaling)
                setScale(Math.min(newScale, 1));
            }
        };

        adjustScale();
        window.addEventListener("resize", adjustScale);

        return () => window.removeEventListener("resize", adjustScale);
    }, [steps]);

    return (
        <div
            ref={containerRef}
            className="steps-display"
            // style={{ transform: `scale(${scale})` }}
        >
            {steps.map((step, index) => (
                <div
                    key={index}
                    className={`step-item ${step.type === "actor" ? "actor-item" : "movie-item"} appear`}
                >
                    <img
                        src={step.image?.url || missingImage}
                        alt={step.type === "movie" ? "Movie" : "Actor"}
                        title={step.name || ""}
                        onError={(e) => (e.target.src = missingImage)}
                    />
                </div>
            ))}
        </div>
    );
};

export default StepsDisplay;
