import React, { useEffect, useMemo } from "react";
import missingImage from "../../images/default-placeholder.webp";

const HintModal = ({ movies, duration, sortPreference, onClose, isShrunk }) => {
    const sortedMovies = useMemo(() => {
        // Sort movies based on preference
        return [...movies].sort((a, b) => {
            if (sortPreference === "popularity") {
                return b.popularity - a.popularity;
            }
            return new Date(b.release_date) - new Date(a.release_date);
        });
    }, [movies, sortPreference]);

    useEffect(() => {
        const timer = setTimeout(onClose, duration * 1000);
        return () => clearTimeout(timer);
    }, [duration, onClose]);
    
    return (
        <div className={`hint-modal ${isShrunk ? "shrink" : ""}`}>
            <div className="hint-modal-header">
                <h3>Hint: Full Movie List</h3>
                <button className="close-button" onClick={onClose}>X</button>
            </div>
            <div className="grid">
                {sortedMovies.map((movie) => {
                    let uniqueKey;
                    if (movie.subtype === "tvshow") {
                        uniqueKey = "t" + movie.id;
                    } else {
                        uniqueKey = "m" + movie.id;
                    }
                    const isTvShow = movie.subtype === "tvshow";
                    return (
                    <div key={uniqueKey} className={`grid-item ${isTvShow ? "tv-item" : ""}`}>
                        <img                                 src={movie.poster?.url || movie.profile_image?.url || missingImage}
                                                             alt={movie.title || movie.name} className="hint-image" />
                        <h4>{movie.title || movie.name}</h4>
                    </div>
                        )
                    })}
            </div>
        </div>
    );
};

export default HintModal;
