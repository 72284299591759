import React, { useState, useEffect } from 'react';
import { fetchChallengeRequests, fetchChallengePlay } from "../../api";
import { useNavigate } from "react-router-dom";
import LeaderBoardModal from "./LeaderBoardModal"; // Import the modal

const ChallengesMenu = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('challenges');
    const [challengeRequests, setChallengeRequests] = useState([]);
    const [pastChallenges, setPastChallenges] = useState([]);
    const [activeMenuId, setActiveMenuId] = useState(null);
    const [leaderBoardChallengeId, setLeaderBoardChallengeId] = useState(null); // State for modal

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setActiveMenuId(null);
    };

    // fetchChallengeRequests on mount
    useEffect(() => {
        async function loadChallengeRequests() {
            try {
                const response = await fetchChallengeRequests();
                // Filter the challenges into current and past (completed)
                setChallengeRequests(response.filter(request => request.status !== 'completed'));
                setPastChallenges(response.filter(request => request.status === 'completed'));
            } catch (error) {
                console.error("Error fetching challenge requests:", error);
            }
        }
        loadChallengeRequests();
    }, []);

    const handleAcceptChallenge = async (challengeId) => {
        try {
            const result = await fetchChallengePlay(challengeId);
            if (result) {
                navigate("/game", { state: { data: result } });
            } else {
                alert("Game not found or failed to copy.");
            }
        } catch (error) {
            console.error("Error during game copy:", error);
            alert("An error occurred. Please try again.");
        }
    };

    // Dummy handlers for menu options; implement as needed.
    const handleDetails = (row) => {
        alert(`Details for challenge with ID: ${row.id}`);
        setActiveMenuId(null);
    };

    const handleRemove = (row) => {
        alert(`Remove challenge with ID: ${row.id}`);
        setActiveMenuId(null);
    };

    // Handler for leader board modal
    const handleLeaderBoard = (row) => {
        // Assumes each challenge has a unique challenge_id property
        setLeaderBoardChallengeId(row.challenge_id);
        setActiveMenuId(null);
    };

    const toggleMenu = (rowId) => {
        setActiveMenuId(prev => (prev === rowId ? null : rowId));
    };

    return (
        <div className="challenges-menu">
            <div className="tabs">
                <button
                    onClick={() => handleTabChange('challenges')}
                    className={activeTab === 'challenges' ? 'active' : ''}
                >
                    Challenges
                </button>
                <button
                    onClick={() => handleTabChange('replay')}
                    className={activeTab === 'replay' ? 'active' : ''}
                >
                    Replay
                </button>
            </div>
            <div className="tab-content">
                {activeTab === 'challenges' && (
                    <div className="challenges-content">
                        {challengeRequests.length > 0 ? (
                            <>
                                <div className="grid-header">
                                    <span>Sender Name</span>
                                    <span>Top Score</span>
                                    <span>Sender Score</span>
                                    <span>Target Actor</span>
                                    <span>Actions</span>
                                    <span>Menu</span>
                                </div>
                                {challengeRequests.map((request, index) => (
                                    <div key={request.id || index} className="grid-row">
                                        <span>{request.sender_name}</span>
                                        <span>{request.top_score}</span>
                                        <span>{request.sender_score}</span>
                                        <span>
                                            {request.game_details && request.game_details.targetActor
                                                ? request.game_details.targetActor.name 
                                                : (request.target_actor || "N/A")}
                                        </span>
                                        <button
                                            onClick={() => handleAcceptChallenge(request.challenge_id)}
                                        >
                                            Play
                                        </button>
                                        <div className="menu-column" style={{ position: 'relative' }}>
                                            <button onClick={() => toggleMenu(request.id || index)}>
                                                &#8942;
                                            </button>
                                            {activeMenuId === (request.id || index) && (
                                                <div className="menu-dropdown dropdown-menu">
                                                    <button onClick={() => handleDetails(request)}>Details</button>
                                                    <button onClick={() => handleLeaderBoard(request)}>Leader Board</button>
                                                    <button onClick={() => handleRemove(request)}>Remove</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <p>No challenges available.</p>
                        )}
                    </div>
                )}
                {activeTab === 'replay' && (
                    <div className="replay-content">
                        {pastChallenges.length > 0 ? (
                            <>
                                <div className="grid-header">
                                    <span>Sender Name</span>
                                    <span>Top Score</span>
                                    <span>Your Score</span>
                                    <span>Your Rank</span>
                                    <span>Target Actor</span>
                                    <span>Replay</span>
                                    <span>Menu</span>
                                </div>
                                {pastChallenges.map((challenge, index) => (
                                    <div key={challenge.id || index} className="grid-row">
                                        <span>{challenge.sender_name}</span>
                                        <span>{challenge.top_score}</span>
                                        <span>{challenge.your_score}</span>
                                        <span>{challenge.your_rank}</span>
                                        <span>
                                            {challenge.game_details && challenge.game_details.targetActor
                                                ? challenge.game_details.targetActor.name 
                                                : (challenge.target_actor || "N/A")}
                                        </span>
                                        <span>
                                            {challenge.multi_submit ? (
                                                <button onClick={() => handleAcceptChallenge(challenge.challenge_id)}>
                                                    Replay
                                                </button>
                                            ) : (
                                                <span>N/A</span>
                                            )}
                                        </span>
                                        <div className="menu-column" style={{ position: 'relative' }}>
                                            <button onClick={() => toggleMenu(challenge.id || index)}>
                                                &#8942;
                                            </button>
                                            {activeMenuId === (challenge.id || index) && (
                                                <div className="menu-dropdown dropdown-menu">
                                                    <button onClick={() => handleDetails(challenge)}>Details</button>
                                                    <button onClick={() => handleLeaderBoard(challenge)}>Leader Board</button>
                                                    <button onClick={() => handleRemove(challenge)}>Remove</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <p>No past challenges found.</p>
                        )}
                    </div>
                )}
            </div>
            {leaderBoardChallengeId && (
                <LeaderBoardModal 
                    challengeId={leaderBoardChallengeId} 
                    onClose={() => setLeaderBoardChallengeId(null)} 
                />
            )}
        </div>
    );
};

export default ChallengesMenu;