import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../api";

const Register = () => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        const success = await registerUser({ username, email, password });
        if (success) {
            alert("Registration successful! Please log in.");
            navigate("/"); // Redirect to login page
        } else {
            alert("Registration failed. Please try again.");
        }
    };

    return (
            <form
                onSubmit={handleRegister}
                style={{
                    maxWidth: "400px",
                    margin: "0 auto",
                    padding: "20px",
                    borderRadius: "8px",
                    border: "1px solid var(--border-color)"
                }}
            >
            <h1 style={{ color: "var(--primary-color)", textAlign: "center" }}>Register</h1>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                //hint a Display name can be updated later
                title="A display name can be updated later"
            />
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
            />
            <button type="submit"
                style={{ 
                    display: "block", 
                    width: "100%", 
                    marginTop: "15px" 
                }}
            >Register</button>
        </form>
    );
};

export default Register;
