import React, { useState, useEffect } from 'react';
import { fetchFriends, createSendChallengeRequest } from '../../api';

const IssueChallenge = ({ onClose, gameDetails }) => {
    // gameDetails should be an object like { parentGameId, score, settings }
    const { parentGameId, score, settings } = gameDetails;

    const [friends, setFriends] = useState([]);
    const [selectedFriends, setSelectedFriends] = useState([]);
    const [multiTry, setMultiTry] = useState(true);
    const [details, setDetails] = useState(true);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function loadFriends() {
            try {
                const data = await fetchFriends();
                setFriends(data || []);
            } catch (err) {
                setError("Failed to load friends list.");
                console.error(err);
            }
        }
        loadFriends();
    }, []);

    const handleCheckboxChange = (friendId) => {
        if (selectedFriends.includes(friendId)) {
            setSelectedFriends(selectedFriends.filter(id => id !== friendId));
        } else {
            setSelectedFriends([...selectedFriends, friendId]);
        }
    };

    const handleSendChallenge = async () => {
        // Basic validation
        if (selectedFriends.length === 0) {
            setError("Please select at least one friend.");
            return;
        }
        // convert selectedFriends to numbers
        const convertedSelectedFriends = selectedFriends.map(Number);

        const payload = {
            parent_game_id: Number(parentGameId),
            receiver_ids: convertedSelectedFriends,
            score,
            settings,
            multi_submit: multiTry,
            view_details: details
        };

        setLoading(true);
        try {
            const response = await createSendChallengeRequest(payload);
            // Handle response if needed
            onClose(); // Close the modal on success
        } catch (err) {
            setError(err.message);
            console.error("Error sending challenge:", err);
        }
        setLoading(false);
    };

    return (
        <div className="modal-overlay challenge-modal">
            <div className="modal">
                <h2>Issue Challenge</h2>
                {error && <p className="error-message">{error}</p>}
                <div className="challenge-details">
                    <p><strong>Parent Game ID:</strong> {parentGameId}</p>
                    <p><strong>Score:</strong> {score}</p>
                    <p><strong>Settings:</strong> {settings}</p>
                    <label>
                        <input
                            type="checkbox"
                            checked={multiTry}
                            onChange={() => setMultiTry(!multiTry)}
                        />
                        Multi Try
                    </label>
                    {/* checkbox for allow detials */}
                    <label>
                        <input
                            type="checkbox"
                            checked={details}
                            onChange={() => setDetails(!details)}
                        />
                        Allow Details
                    </label>
                </div>
                <div className="friends-list">
                    <h3>Select Friends</h3>
                    {friends.length > 0 ? (
                        friends.map(friend => {
                            // Use a unique identifier from your friend object.
                            const friendId = friend.user_id || friend.id;
                            return (
                                <div key={friendId} className="grid-row">
                                    <input
                                        type="checkbox"
                                        checked={selectedFriends.includes(friendId)}
                                        onChange={() => handleCheckboxChange(friendId)}
                                    />
                                    <span>{friend.display_name}</span>
                                    <span>{friend.username}</span>
                                </div>
                            );
                        })
                    ) : (
                        <p>No friends found.</p>
                    )}
                </div>
                <div className="modal-actions">
                    <button onClick={handleSendChallenge} disabled={loading}>
                        {loading ? "Sending..." : "Send Challenge"}
                    </button>
                    <button onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default IssueChallenge;