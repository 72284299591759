import React, { useState, useEffect } from "react";
import { fetchProfile, updateProfile } from "../api";

function PreferencesModal({ onClose, onSave }) {
    const [displayName, setDisplayName] = useState("");
    const [movieSort, setMovieSort] = useState("date");
    const [actorSort, setActorSort] = useState("date");
    const [hintSort, setHintSort] = useState("date");
    const [movieFilter, setMovieFilter] = useState([]); // Stores selected genres
    const [moviePopularity, setMoviePopularity] = useState(0); // Popularity slider value

    const genres = [
        { id: 10402, name: "Music" },
        { id: 99, name: "Documentary" },
        { id: 10770, name: "TV Movie" },
    ];

    // Load preferences on mount
    useEffect(() => {
        async function loadPreferences() {
            const profile = await fetchProfile();
            if (profile) {
                setDisplayName(profile.display_name || "");
                setMovieSort(profile.preferences?.sort_movies || "date");
                setActorSort(profile.preferences?.sort_actors || "date");
                setHintSort(profile.preferences?.sort_hints || "date");
                setMovieFilter(profile.preferences?.movie_filter?.split(",").map(Number) || []);
                setMoviePopularity(profile.preferences?.movie_pop_filter || 0);
            }
        }
        loadPreferences();
    }, []);

    const handleSave = async () => {
        const updatedPreferences = {
            sort_movies: movieSort,
            sort_actors: actorSort,
            sort_hints: hintSort,
            movie_filter: movieFilter.join(","), // Convert array to comma-separated string
            movie_pop_filter: moviePopularity,
        };

        const success = await updateProfile({
            preferences: updatedPreferences,
            display_name: displayName,
        });

        if (success) {
            alert("Preferences saved!");
            onSave(updatedPreferences); // Notify the parent component
            onClose();
        } else {
            alert("Failed to save preferences.");
        }
    };

    const toggleGenre = (id) => {
        setMovieFilter((prev) =>
            prev.includes(id) ? prev.filter((genreId) => genreId !== id) : [...prev, id]
        );
    };

    return (
        <div className="modal-overlay">
            <div className="modal">
                <h2>Preferences</h2>
                <div className="option">
                    <label>Display Name:</label>
                    <input
                        type="text"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                    />
                </div>
                <div className="option">
                    <label>Movie Grid Sorting:</label>
                    <select value={movieSort} onChange={(e) => setMovieSort(e.target.value)}>
                        <option value="date">Date</option>
                        <option value="popularity">Popularity</option>
                    </select>
                </div>
                <div className="option">
                    <label>Actor Grid Sorting:</label>
                    <select value={actorSort} onChange={(e) => setActorSort(e.target.value)}>
                        <option value="date">Date</option>
                        <option value="popularity">Popularity</option>
                    </select>
                </div>
                <div className="option">
                    <label>Hint Sorting:</label>
                    <select value={hintSort} onChange={(e) => setHintSort(e.target.value)}>
                        <option value="date">Date</option>
                        <option value="popularity">Popularity</option>
                    </select>
                </div>
                <div className="option">
                    <label>Movie Exclusion Filter:</label>
                    <div>
                        {genres.map((genre) => (
                            <label key={genre.id}>
                                <input
                                    type="checkbox"
                                    checked={movieFilter.includes(genre.id)}
                                    onChange={() => toggleGenre(genre.id)}
                                />
                                {genre.name}
                            </label>
                        ))}
                    </div>
                </div>
                <div className="option">
                    <label>Movie Popularity Exclusion Filter:</label>
                    <input
                        type="range"
                        min="0"
                        max="10"
                        step="0.1"
                        value={moviePopularity}
                        onChange={(e) => setMoviePopularity(parseFloat(e.target.value))}
                    />
                    <span>{moviePopularity.toFixed(1)}</span>
                </div>
                <div className="modal-actions">
                    <button onClick={handleSave}>Save</button>
                    <button onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default PreferencesModal;
