import React, {useEffect, useMemo, useRef, useState} from "react";
import { useLocation } from "react-router-dom";
import ActorDisplay from "./ActorDisplay";
import Grid from "./Grid";
import GameStatsPopup from "./GameStatsPopup";
import {pickOption, moveBack, askHint, fetchProfile, gameStart, copyGame, askHintActor, askHintMovie} from "../../api";
import HintModal from "./HintModal";
import GameBar from "./GameBar";
import { useNavigate } from "react-router-dom";
import PreferencesModal from "../PreferencesModal";
import IssueChallenge from "../Challenges/IssueChallenge";


const Game = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data } = location.state || {};
    const [showPreferences, setShowPreferences] = useState(false);
    const [preferences, setPreferences] = useState(null); // Store preferences
    const openPreferences = () => setShowPreferences(true);
    const closePreferences = () => setShowPreferences(false);
    const [loadingPreferences, setLoadingPreferences] = useState(false);
    const [movieSort, setMovieSort] = useState("date");
    const [actorSort, setActorSort] = useState("popularity");
    const [hintSort, setHintSort] = useState("popularity");
    const [movieFilter, setMovieFilter] = useState([]); // Genres filter
    const [moviePopularity, setMoviePopularity] = useState(0); // Popularity filter
    const [targetActor, setTargetActor] = useState(data?.targetActor || null);
    const [hintCount, setHintCount] = useState(data?.stats?.remaining_hints)
    
    const [items, setItems] = useState([
        ...(data?.startingMovies || []),
        ...(data?.startingTVShows || [])
    ]);
    const [isMovieGrid, setIsMovieGrid] = useState(true); // Start with movie grid
    const [stats, setStats] = useState(data?.stats || { jumps: 0 });
    const [steps, setSteps] = useState(data?.steps || []); // Track steps for visualization
    const [gamePath, setGamePath] = useState(data?.options?.path || null);
    const [stepLimit, setStepLimit] = useState(data?.options?.stepLimit || 6);
    const [remainingBackMoves, setRemainingBackMoves] = useState(data?.stats?.remainingBackMoves || 1);
    const [showStats, setShowStats] = useState(false);
    const [gameId, setGameId] = useState(data?.gameId || null);
    const [parent_game_id, setParent_game_id] = useState(data?.parentId || null);
    const [loading, setLoading] = useState(false); // Track loading state for clicks
    const [showHint, setShowHint] = useState(false);
    const [hintMovies, setHintMovies] = useState([]);
    const [hintDuration, setHintDuration] = useState(data?.options?.hintDuration || 10);
    const [remainingTime, setRemainingTime] = useState(data?.stats?.remaining_time || 300); // Default to 5 mins
    const [isGameOver, setIsGameOver] = useState(false);
    const [isFilterEnabled, setIsFilterEnabled] = useState(true); // Toggle for filtering
    const [hideMovies, setHideMovies] = useState(false);
    const [hideTvShows, setHideTvShows] = useState(false);
    const [decade, setDecade] = useState(data?.options?.decade || 0);
    const [randomActor, setRandomActor] = useState(data?.options?.randomActor || false);
    const [actionMsg, setActionMsg] = useState(null); // Track action message
    const [actionMsgType, setActionMsgType] = useState(null); // Track action type
    const [issueChallengeDetails, setIssueChallengeDetails] = useState(null);
    const toggleFilter = () => {
        setIsFilterEnabled(!isFilterEnabled);
    };

    const filteredItems = useMemo(() => {
        if (!items) return []; // Always return something
        if (!isMovieGrid || !isFilterEnabled) {
            // If it's the actor grid or filtering is disabled, return items unfiltered
            return items;
        }
        return items.filter((item) => {
            // Original movie filters based on genres and popularity
            const isGenreExcluded =
                movieFilter?.length > 0
                    ? movieFilter.some((filterGenre) =>
                          (Array.isArray(item.genres)
                              ? item.genres
                              : item.genres.split(",").map(Number)
                          ).includes(filterGenre)
                      )
                    : false;
            const isPopularityExcluded = item.popularity < (moviePopularity || 0);
    
            // New filtering based on TV shows/movies toggles:
            // If hideMovies is true, exclude items that are movies (or lack a subtype, defaulting to movie)
            if (hideMovies && (item.subtype === "movie" || !item.subtype)) {
                return false;
            }
            // If hideTvShows is true, exclude items that are tvshows
            if (hideTvShows && item.subtype === "tvshow") {
                return false;
            }
            return !isGenreExcluded && !isPopularityExcluded;
        });
    }, [items, movieFilter, moviePopularity, isFilterEnabled, isMovieGrid, hideMovies, hideTvShows]);
    
    const [isShrunk, setIsShrunk] = useState(false);

    const hasLoaded = useRef(false); // Prevent duplicate calls
    const handleScroll = () => {
        const scrollY = window.scrollY;
        setIsShrunk(scrollY > 50); // Shrink when scrolled more than 50px
    };
    const handleTimeUp = () => {
        //update stats time
        setStats((prev) => ({ ...prev, remaining_time: 0 }));
        setStats((prev) => ({ ...prev, lose_condition: "time_out" }));
        GameOverSetup();

    };
    const GameOverSetup = () => {
        setIsGameOver(true);
        setShowStats(true);

    }
    const currentPathSpot = useMemo(() => {
        if (!gamePath || gamePath.length === 0) return null;
        const currentStepIndex = steps.length; // Current step index
        return gamePath[currentStepIndex] || null; // Return the current spot or null
    }, [gamePath, steps]);
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    useEffect(() => {
        // Fetch preferences if not already loaded
        const loadPreferences = async () => {
            if (!preferences) {
                setLoadingPreferences(true);
                const profile = await fetchProfile();
                if (profile?.preferences) {
                    setPreferences(profile.preferences);
                    setMovieSort(profile.preferences.sort_movies || "date");
                    setActorSort(profile.preferences.sort_actors || "date");
                    setMovieFilter(profile.preferences.movie_filter?.split(",").map(Number) || []);
                    setMoviePopularity(profile.preferences.movie_pop_filter || 0);
                }
                setLoadingPreferences(false);
            }
        };

        // used to refresh on game start
        if (hasLoaded.current) return; // Exit if already loaded
        hasLoaded.current = true; // Mark as loaded
        gameStart(gameId);
        loadPreferences();

    }, [preferences]);
    const handlePreferencesUpdate = (updatedPreferences) => {
        setPreferences(updatedPreferences);
        setMovieSort(updatedPreferences.sort_movies || "date");
        setActorSort(updatedPreferences.sort_actors || "date");
        setMovieFilter(updatedPreferences.movie_filter?.split(",").map(Number) || []);
        setMoviePopularity(updatedPreferences.movie_pop_filter || 0);
    };

    if (loadingPreferences) {
        return <div>Loading preferences...</div>;
    }

    const handleRedirectAndQuickPlay = async (e) => {
        e?.preventDefault();
        navigate("/loading", {
            state: {
                options: {
                    //todo: clean up this as waht is retruned is not waht makes a new game
                    hintDuration: data?.options?.hintDuration || 10,
                    secGameTimeLimit: data?.options?.time_limit || 300,
                    backMoves: data?.options?.backMoves || 1,
                    stepLimit: data?.options?.stepLimit || 6,
                    hints: data?.options?.hints || 1,
                    randomActor: data?.options?.randomActor || false,
                    decade: data?.options?.decade || 0,
                    requiredPick:  data?.options?.requiredPick || false,
                    genreBonus: data?.options?.genreBonus || true,
                    omitPicks: data?.options?.omitPicks || true,
                    mediaType: data?.options?.mediaType || "MOVIE",

                },
            },
        });
        };

    const handleReplay = async () => {
        try {
            const result = await copyGame(gameId); // Call backend API to copy game
            if (result) {
                navigate("/game", { state: { data: result } });
                //reload the page
                window.location.reload();
            } else {
                alert("Game not found or failed to copy.");
            }
        } catch (error) {
            console.error("Error during game copy:", error);
            alert("An error occurred. Please try again.");
        }
    }

    const handleShare = () => {
        setIssueChallengeDetails({
            parentGameId: parent_game_id,
            score: stats.score,
            settings: stats.settings,
        });
    };

    const handleMovieClick = async (movie) => {
        if (loading) return; // Prevent multiple clicks
        setLoading(true);
        try {
            const result = await pickOption(gameId, "media", movie.id, movie.subtype);
            if (result) {
                window.scrollTo(0, 0);
                setItems(result.media || result.actors || []); // Update grid items
                setIsMovieGrid(result.currentStepType === "media"); // Update grid type
                setSteps(result.steps || []); // Update steps
                setStats(result.stats); // Update stats
                setHintCount(result.stats.remaining_hints)
                setRemainingTime(result.stats.remaining_time || remainingTime);
                setRemainingBackMoves(result.stats.remainingBackMoves || 0);
                setActionMsg(result.actionMsg || null); // Update action message
                setActionMsgType(result?.actionMsgType || null); // Update action type
                if (result.stats?.gameOver) GameOverSetup(); // Show stats if game over
            }
        } catch (error) {
            console.error("Error during movie pick:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleActorClick = async (actor) => {
        if (loading) return; // Prevent multiple clicks
        setLoading(true);
        try {
            const result = await pickOption(gameId, "actor", actor.id);
            if (result) {
                // scroll to top of page
                window.scrollTo(0, 0);

                setItems(result.media || result.actors || []); // Update grid items
                setIsMovieGrid(result.currentStepType === "media"); // Update grid type
                setSteps(result.steps || []); // Update steps
                setStats(result.stats); // Update stats
                setHintCount(result.stats.remaining_hints)
                setRemainingTime(result.stats.remaining_time || remainingTime);
                setRemainingBackMoves(result.stats.remainingBackMoves || 0);
                setActionMsg(result.actionMsg || null); // Update action message
                setActionMsgType(result.actionMsgType || null); // Update action type
                if (result.stats?.gameOver) GameOverSetup(); // Show stats if game over
            }
        } catch (error) {
            console.error("Error during actor pick:", error);
        } finally {
            setLoading(false);
        }
    };

    const dismissActionMessage = () => {
        setActionMsg(null);
        setActionMsgType(null);
    };

    const handleHint = async () => {
        try {
            const result = await askHint(gameId); // Fetch hint data
            if (result) {
                setHintMovies(result.media || []);
                setHintDuration(result.hintDuration || 10);
                setShowHint(true);
                setHintCount(hintCount-1)
            }
        } catch (error) {
            console.error("Error fetching hint:", error);
        }
    };
    const handleHintActor = async (id) => {
        try {
            const result = await askHintActor(gameId, id); // Fetch hint data
            if (result) {
                setHintMovies(result.media || []);
                setHintDuration(result.hintDuration || 10);
                setShowHint(true);
                setHintCount(hintCount-1)
            }
        } catch (error) {
            console.error("Error fetching hint:", error);
        }
    }

    const handleHintMovie = async (id) => {
        try {
            const result = await askHintMovie(gameId, id); // Fetch hint data
            if (result) {
                setHintMovies(result.actors || []);
                setHintDuration(result.hintDuration || 10);
                setShowHint(true);
                setHintCount(hintCount-1)
            }
        } catch (error) {
            console.error("Error fetching hint:", error);
        }
    }

    const handleMoveBack = async () => {
        if (loading) return; // Prevent multiple actions
        setLoading(true);
        try {
            const result = await moveBack(gameId);
            if (result) {
                window.scrollTo(0, 0);
                setItems(result.media || result.actors || []); // Update grid items
                setIsMovieGrid(result.currentStepType === "media"); // Update grid type
                setSteps(result.steps || []); // Update steps
                setStats(result.stats); // Update stats
                setRemainingBackMoves(result.stats.remainingBackMoves || 0);
                setRemainingTime(result.stats.remaining_time || remainingTime);
                setActionMsg(result.actionMsg || null); // Update action message
                setActionMsgType(result.actionMsgType || null); // Update action type
                if (result.steps.length === 0) {
                    // If no steps are left, reset to starting point
                    setTargetActor(result.targetActor);
                    setHintCount(result.stats.remaining_hints);
                    let startingMedia = result.startingMovies + result.startingTVShows;
                    setItems(startingMedia);
                    setIsMovieGrid(true); // Reset to movie grid
                }
            }
        } catch (error) {
            console.error("Error during move back:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="game-container">
            <div className="game-container">
                <GameBar
                    startingActor={data?.startingActor}
                    targetActor={targetActor}
                    hints={hintCount}
                    onHint={handleHint}
                    onHintActor={handleHintActor}
                    onHintMovie={handleHintMovie}
                    steps={steps}
                    gamePath={gamePath}
                    remainingTime={remainingTime}
                    isGameOver={isGameOver}
                    onTimeUp={handleTimeUp}
                    gameId={gameId}
                    onNewGame={handleRedirectAndQuickPlay}
                    handleMoveBack={handleMoveBack}
                    onPreferencesClick={openPreferences}
                    remainingBackMoves={remainingBackMoves}
                    stepsLimit={stepLimit}
                />
            </div>
            {showPreferences && (
                <PreferencesModal
                    onClose={closePreferences}
                    onSave={handlePreferencesUpdate}
                />
            )}
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
            <div className="grid-controls">
                <button onClick={toggleFilter}>
                    {isFilterEnabled ? "Disable Filters" : "Enable Filters"}
                </button>
                {isMovieGrid && (
                <>
                    <button
                        onClick={() => setHideMovies((prev) => !prev)}
                        style={{
                            marginLeft: "10px",
                            backgroundColor: hideMovies ? "gray" : "blue",
                            color: "white",
                        }}
                    >
                        Movies
                    </button>
                    <button
                        onClick={() => setHideTvShows((prev) => !prev)}
                        style={{
                            marginLeft: "10px",
                            backgroundColor: hideTvShows ? "gray" : "blue",
                            color: "white",
                        }}
                    >
                        TV Shows
                    </button>
                </>
            )}
            </div>
            {actionMsgType === 1 && actionMsg && (
                <div className="action-message" onClick={dismissActionMessage}>
                    {actionMsg}
                </div>
            )}
            <Grid
                items={filteredItems}
                onItemClick={isMovieGrid ? handleMovieClick : handleActorClick}
                isMovieGrid={isMovieGrid}
                sortPreference={movieSort} // Pass sorting preference
                currentGenreBonus={currentPathSpot?.genreBonus}
                currentRequiredPick = {currentPathSpot?.id}
                stats={stats}

            />

            {/*this is the game over*/}
            {showStats && (
                <GameStatsPopup
                    stats={stats}
                    steps={steps}
                    onReplay={handleReplay}
                    onClose={() => setShowStats(false)}
                    onPlayAgain={handleRedirectAndQuickPlay}
                    onShare={handleShare}
                />
            )}
            {showHint && (
                <HintModal
                    movies={hintMovies}
                    duration={hintDuration}
                    sortPreference={hintSort} // Pass hint sorting preference
                    onClose={() => setShowHint(false)}
                    isShrunk={isShrunk}
                />
            )}

            {/* Conditionally render IssueChallenge modal */}
            {issueChallengeDetails && (
            <IssueChallenge 
                gameDetails={issueChallengeDetails} 
                onClose={() => setIssueChallengeDetails(null)}
            />
            )}
        </div>
    );
};

export default Game;