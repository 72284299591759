import React, { useState, useMemo } from "react";
import missingImage from "../../images/default-placeholder.webp";

const Grid = ({ items, onItemClick, isMovieGrid, sortPreference, currentGenreBonus, stats, currentRequiredPick  }) => {
    const genreMapping = {
        28: "Action",
        12: "Adventure",
        16: "Animation",
        35: "Comedy",
        80: "Crime",
        99: "Documentary",
        18: "Drama",
        10751: "Family",
        14: "Fantasy",
        36: "History",
        27: "Horror",
        10402: "Music",
        9648: "Mystery",
        10749: "Romance",
        878: "Science Fiction",
        10770: "TV Movie",
        53: "Thriller",
        10752: "War",
        37: "Western",
    };
    const getGenreNames = (genreIds) => {
        return genreIds
            .split(",") // Split comma-separated genre IDs
            .map((id) => parseInt(id.trim())) // Convert to integers
            .filter((id) => !isNaN(id)); // Ensure valid integers
    };

    const sortedItems = useMemo(() => {
        return [...items].sort((a, b) => {
            if (isMovieGrid) {
                if (sortPreference === "popularity") {
                    return b.popularity - a.popularity;
                }
                return new Date(b.release_date) - new Date(a.release_date);
            } else {
                // For actor grid, always sort by popularity
                return b.popularity - a.popularity;
            }
        });
    }, [items, sortPreference, isMovieGrid]);

    return (
        <div className="grid-container">
            <div className="grid">
                {sortedItems.map((item) => {
                    // Unique key logic based on grid type and item subtype
                    let uniqueKey;
                    if (isMovieGrid) {
                        // For media items, use subtype to determine the prefix
                        if (item.subtype === "tvshow") {
                            uniqueKey = "t" + item.id;
                        } else {
                            uniqueKey = "m" + item.id;
                        }
                    } else {
                        // For actor grid, use a different prefix
                        uniqueKey = "a" + item.id;
                    }

                    // Parse genres into a list of IDs
                    const genreIds = item.genres ? getGenreNames(item.genres) : [];

                    // Check if the item matches the current bonus genre
                    const isBonus = currentGenreBonus && genreIds.includes(currentGenreBonus);
                    const isTvShow = item.subtype === "tvshow";

                    // Get the name of the matching bonus genre
                    const bonusGenreName = isBonus ? genreMapping[currentGenreBonus] : null;
                    // ommited class uesing  stats.omittedMoviesIds or stats.omittedActorsIds
                    const isOmitted = (item) => {
                        if (isMovieGrid) {
                            return (stats.omittedMoviesIds.includes(item.id) && item.id !== currentRequiredPick); // || (stats.omittedTVIds.includes(item.id) && item.id !== currentRequiredPick)
                        } else {
                            return stats.omittedActorsIds.includes(item.id) && item.id !== currentRequiredPick;
                        }
                    }
                    const isARequiredPick = (item) => {
                        return item.id === currentRequiredPick;
                    }

                    return (
                        <div
                            key={uniqueKey}
                            className={`grid-item ${isBonus ? "bonus-highlight" : ""} ${isOmitted(item) ? "omitted" : ""} ${isARequiredPick(item) ? "required" : ""}  ${isTvShow ? "tv-item" : ""}`}
                            onClick={() => onItemClick(item)}
                        >
                            {isBonus && <p className="bonus-label">Genre Bonus: {bonusGenreName}</p>}
                            {isOmitted(item) && <p className="omitted-label">Unavailable</p>}
                            <img
                                src={item.poster?.url || item.profile_image?.url || missingImage}
                                alt={item.title || item.name}
                                onError={(e) => (e.target.src = missingImage)} // Replace with default image on error
                            />
                            <h3>{item.title || item.name}</h3>
                            {item.release_date && <p>{item.release_date}</p>}

                        </div>
                    );
                })}
            </div>
        </div>

    );
};

export default Grid;
