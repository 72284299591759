import React from "react";
import Timer from "./Timer";

const StepStats = ({ remainingTime, stepCount, onTimeUp, isGameOver }) => {
    return (
        <div className="step-stats">
            <div className="timer-section">
                <Timer
                    remainingTime={remainingTime}
                    onTimeUp={onTimeUp}
                    isGameOver={isGameOver}
                />
            </div>
            <div className="step-count">
                Steps: <span>{stepCount}</span>
            </div>
        </div>
    );
};

export default StepStats;