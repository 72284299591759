import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../api";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    // Check if the user is already logged in
    useEffect(() => {
        const checkSession = async () => {
            const sessionId = document.cookie.split("; ").find((row) => row.startsWith("sessionId="));
            if (sessionId) {
                // You can optionally verify the session with the backend
                navigate("/menu"); // Redirect to main menu if session exists
            }
        };

        checkSession();
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        const result = await loginUser({ username, password });
        if (result) {
            document.cookie = `sessionId=${result.sessionId}; path=/;`;
            navigate("/menu"); // Redirect to main menu on successful login
        } else {
            alert("Invalid credentials");
        }
    };

    return (
        <form onSubmit={handleLogin} style={{ maxWidth: "400px", margin: "0 auto", padding: "20px", borderRadius: "8px", border: "1px solid var(--border-color)" }}>
            <h1 style={{ color: "var(--primary-color)", textAlign: "center" }}>Login</h1>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                autoCapitalize="none"
                autoCorrect="off"
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <button
                type="submit"
                style={{ 
                    display: "block", 
                    width: "100%", 
                    marginTop: "15px" 
                }}
            >
            Login
        </button>
            <p style={{ textAlign: "center" }}>
                Don't have an account? <a href="/register">Register here</a>
            </p>
        </form>
    );
};

export default Login;

